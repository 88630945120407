<template>
    <div class="w-full flex-col">
        <div class="w-full flex-row">
            <div class="w-10 b-b b-l b-r tab-w-15 tel-w-30" id="yearTagRealisation">
                <p>en<span>2021</span></p>
            </div>
            <div class="w-90 b-b b-r tab-w-85 tel-w-70">
                 
            </div>
        </div>
        <RealisationCard v-for="(card, index) in dataRealisations" :info_realisation="card" :key="index" />
        <router-link to="/realisations" class="w-full flex-row">
            <div class="w-12 bg-pink txt-blue b-b b-l b-r tab-w-21" id="pointsIconMoreRealisation">
                ...
            </div>
            <div class="w-88 flex-row jc-fs ai-c txt-yellow b-b b-r as-s" id="txtMoreRealisation">
                plus de projet ...
            </div>
        </router-link>
    </div>
</template>

<script>
//BDD
import database from '../databases/__realisations'

import { onMounted, ref } from 'vue'
// COMPONENTS
import RealisationCard from "../components/_partials/RealisationCard.vue";

export default {
    name: 'RealisationMinimized',
    components: {        
        RealisationCard,
    },
    setup() {
        class Realisations {
            constructor (name, link, summary, image, date, fav) {
                this.name = name
                this.link = link
                this.summary = summary
                this.image = image
                this.date = date
                this.fav = fav
            }
        }

        let dataRealisations = ref([])

        const makeDataRealisations = () => {

            for (const realisation of database) {
                const new_realisation = new Realisations(realisation.name, realisation.link, realisation.summary, realisation.image, realisation.date)
                if(realisation.fav) {
                    dataRealisations.value.push(new_realisation)
                }
            }
        }

        onMounted(makeDataRealisations);
        
        //Return
        return {
            dataRealisations,
        };
    }
}
</script>