<template>
    <div class="w-full flex-row tel-flex-col">
        <div class="w-33 flex-col tab-w-45 tel-w-full">
            <div class="w-full flex-row jc-fe ai-fe as-s b-b b-r b-l" id="windowIcons">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 17">
                    <path d="M51.8,8.2l-4.9-4.9l1.4-1.4l4.9,4.9l4.9-4.9l1.4,1.4l-4.9,4.9l5.3,5.3l-1.4,1.4l-5.3-5.3l-5.3,5.3l-1.4-1.4 L51.8,8.2z"/>
                    <g>
                        <path d="M23,16h11V5H23V16z M24,6h9v2h-9V6z"/>
                        <polygon points="26.5,0.5 26.5,4 35,4 35,12.5 38.5,12.5 38.5,0.5 	"/>
                    </g>
                    <rect y="14" width="15" height="2"/>
                </svg>
            </div>
            <div
                class="w-full flex-row b-b b-r b-l image"
                :style="changeBackground" >

            </div>
        </div>
        <div class="w-67 flex-col tab-w-55 tel-w-full">
            <div class="w-full bg-yellow txt-green b-b b-r txt-nw tel-b-l" id="nameRealisationCard">
                &gt; {{ info_realisation.name }}
            </div>
            <div class="w-full flex-col gap-lg b-b b-r tab-gap-sm tel-b-l" id="infosRealisationCard">
                <div class="flex-row" id="linkRealisationCard">
                    <p class="txt-blue">lien:&nbsp;</p> 
                    <a v-if="info_realisation.link" v-bind:href="info_realisation.link" target="_blank" rel="external nofollow" class="txt-yellow">
                        <p>"<span id="linkText">visiter le site</span>"</p>
                    </a>
                    <p v-else>no link</p>
                </div>
                <div class="infoContainer">
                    <p class="txt-blue">info:</p>
                    <p id="textInfoRealisationCard">{{ info_realisation.summary }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// IMPORT
import { computed } from 'vue'

export default {
    name: 'RealisationCard',
    props: {
        info_realisation: Object,
    },
    setup(props) {
        const changeBackground = computed(() => {
            return {
                backgroundImage: `url(${props.info_realisation.image})`
            }
        })
        //Return
        return {
            changeBackground
        }
    }
}
</script>