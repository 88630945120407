const realisations = [
    {
        name: 'Denis Daubos - Chocolatier Pâtissier',
        link: 'https://daubos-chocolatierpatissier.fr/',
        summary: 'Présenter le savoir-faire artisanal d’un chocolatier-pâtissier d’envergure, finaliste du Grand Prix international de la Chocolaterie, ancien pâtissier du ministre de la Défense. Nous avons repris la dynamique de sa boutique pour la retranscrire sur le web.',
        image: 'https://www.arnaudlaborde.fr/realisations/dauboschocolatier.png',
        date: '2022-03-15',
        fav: true,
    },{
        name: 'Tot\'m',
        link: false,
        summary: 'Ce projet a été réalisé lors des études, une entreprise en création en région bordelaise qui voulait offrir un portail de divertissement en région bordelaise. La maquette est construite autour de référence donnée par le client ainsi que des analyses des concurrents ou marchés similaires.',
        image: 'https://www.arnaudlaborde.fr/realisations/totm_medium.png',
        date: '2021-04-01',
    },{
        name: 'Dronister',
        link: false,
        summary: 'Vendre une vision d’un produit révolutionnaire à des actionnaires. Ce projet futuriste a été réalisé pour un partiel lors d’étude. Le besoin était de développer et démocratiser l’image du drone taxi tout en recherchant dans un même temps des investisseurs potentiels.',
        image: 'https://www.arnaudlaborde.fr/realisations/dronister_medium.png',
        date: '2021-03-01',
    },{
        name: 'Ferme des Vallons',
        link: 'https://www.fermedesvallons.fr',
        summary: 'Ce site utilise le CMS Prestashop et se repose sur le thème par défaut qui a été modifié par-dessus avec un thème enfant. L’objectif : ne pas en faire trop ; c’est une petite entreprise familiale qui fait de la vente directe.',
        image: 'https://www.arnaudlaborde.fr/realisations/fermedesvallons_medium.png',
        date: '2020-10-01',
        fav: true,
    },
]
module.exports = realisations;